import React, { FC } from "react";
import { Box, Text } from "theme-ui";
import styles from "./landing.style";
import LandingSliceBase from "components/landing-slice-base/landing-slice-base";

type PropsType = {
  content: any;
  title?: string;
};
const CGELanding: FC<PropsType> = ({ content, title }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        {content.length && <LandingSliceBase content={content} title={title} />}
      </Box>
    </Box>
  );
};
export default CGELanding;
