import React, { FC } from "react";
import ContentContainer from "../components/container/content-container";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import CGELanding from "components/blog/cge-landing";

export const query = graphql`
  query {
    prismicEventLandingPage(prismicId: { eq: "ZS12NxEAACMAv-FO" }) {
      data {
        body {
          ... on PrismicEventLandingPageDataBodyArticleBlock {
            id
            slice_type
            items {
              content {
                raw
              }
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
              }
              link {
                uid
                url
              }
              position
            }
          }
          ... on PrismicEventLandingPageDataBodyLanding {
            id
            primary {
              landing_image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyNewsSection {
            id
            items {
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link_new_images {
                uid
                url
              }
              title_news {
                raw
              }
              subtitle {
                raw
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyText {
            id
            primary {
              text_block {
                raw
              }
            }
            slice_type
          }
          ... on PrismicEventLandingPageDataBodyArticlesSection {
            id
            slice_type
            primary {
              link {
                uid
                url
                link_type
              }
            }
            items {
              headline {
                raw
              }
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
              }
              link {
                uid
                url
              }
            }
          }
        }
        metadescription {
          raw
        }
      }
    }
  }
`;

interface CGEPageProps {
  data: any;
}

const CGEPage: FC<CGEPageProps> = ({ data }) => {
  const content = data.prismicEventLandingPage.data.body;
  const description = data.prismicEventLandingPage.data.metadescription.raw[0];

  return (
    <PrimaryLayout>
      <SEO title="CGE" description={description} />
      <ContentContainer width="100%">
        {/* CGELanding component */}
        <CGELanding content={content} title="CGE" />
        {/* ColorDivider component */}
      </ContentContainer>
    </PrimaryLayout>
  );
};

export default CGEPage;
